import { toast } from 'react-toastify';
import { compose, over, lensProp } from 'ramda';

import {
  createNewEstablishment,
  updateEstablishment,
  fetchBusinessInfo,
  activePremiumPackage,
  disablePremiumPackage,
  debitWarning
} from './service';

import {
  setEstablishmentId,
  setBusinessInfo,
  handleLoading,
  handlePostalCode,
  setEditingState,
  setEstablishmentTypes
} from './actions';

import { setNewEstablishment, setUpdatedEstablishment } from '../../Login/store/actions';
import { fetchAddress } from '../../../helpers/externalServices';
import { formatToOnlyNumbers } from '../../../helpers/formatters';
import { setBaseAPI } from '../../../services';

export const loadBusinessInfo = params => dispatch => {
  dispatch(handleLoading(true));

  return fetchBusinessInfo(params)
    .then(({ data }) => dispatch(setBusinessInfo(data)))
    .finally(() => dispatch(handleLoading(false)));
}

export const upsertEstablishment = params => dispatch => {
  const formatParams = compose(
    over(lensProp('phone'), formatToOnlyNumbers),
    over(lensProp('postalCode'), formatToOnlyNumbers),
    over(lensProp('documentId'), formatToOnlyNumbers)
  );

  dispatch(handleLoading(true));

  if(!!params.establishmentId) {
    delete params.resaleId;
  }

  if(!params.establishmentId) {
    delete params['establishmentId'];

    const formattedParams = {
      ...params,
      appPermission: 1
    }

    return createNewEstablishment(formatParams(formattedParams))
      .then(({ data }) => {
        const { userId, establishmentId, establishmentTypes } = data;

        if(!params?.isInitialRegistration) {
          toast.success('Cadastro efetuado com sucesso! Procure por "Jump Park" na Play Store e comece a operar.');
        }

        if(params?.isInitialRegistration) {
          if(params?.establishmentTypes?.includes(1)) {
            toast.success('Estabelecimento criado. Agora cadastre sua tabela de preço');
          }
        }

        setBaseAPI(userId, establishmentId);

        dispatch(setNewEstablishment(data));
        dispatch(setEstablishmentId({ establishmentId }));
        dispatch(setEstablishmentTypes(establishmentTypes));

        return data;
      })
      .finally(() => dispatch(handleLoading(false)));
  }

  return updateEstablishment(formatParams(params))
    .then(({ data }) => {
      toast.success('Edição feita! Para atualizar no aplicativo saia e acesse novamente no próprio aplicativo.');
      dispatch(setUpdatedEstablishment(data));
      return { ...data, isUpdated: true };
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const changeEstablishmentPermission = (
  param,
  userId,
  establishmentId,
  returnEstablishmentTypes = false
) => dispatch => {
  dispatch(handleLoading(true));

  const params = {
    ...param,
    userId,
    establishmentId
  }

  return updateEstablishment(params)
    .then(({ data }) => {
      const textFeedBack =
        param && param.debitWarning
          ? 'Estabelecimento atualizado com sucesso!'
          : 'Permissão do estabelecimento atualizada!';

      toast.success(textFeedBack);

      dispatch(setBusinessInfo(data));
      dispatch(setEditingState(false));

      return returnEstablishmentTypes && { ...data, isUpdated: true };
    })
    .finally(() => dispatch(handleLoading(false)));
}

export const loadAddress = value => dispatch => {
  const postalCode = value.replace(/-/g, '');

  dispatch(handlePostalCode(true));

  return fetchAddress(postalCode)
    .then(data => {
      const { erro } = data;

      if(erro) {
        dispatch(handlePostalCode(false));
        return toast.error('Não conseguimos encontrar o seu CEP');
      }

      return data;
    })
    .catch(() => toast.error('Problemas no servidor, tente novamente mais tarde'))
    .finally(() => dispatch(handlePostalCode(false)));
}

export const setActivePremiumPackage = (userId, establishmentId) => dispatch => {
  return activePremiumPackage(userId, establishmentId)
    .then(({ data }) => {
      toast.success(data);
      dispatch(loadBusinessInfo({ userId, establishmentId }));
    })
    .catch(() => toast.error('Houve um erro ao ativar o Pacote Premium.'));
}

export const setDisablePremiumPackage = (userId, establishmentId) => dispatch => {
  return disablePremiumPackage(userId, establishmentId)
    .then(({ data }) => {
      toast.success(data);
      dispatch(loadBusinessInfo({ userId, establishmentId }));
    })
    .catch(() => toast.error('Houve um erro ao desativar o Pacote Premium.'));
}

export const setDebitWarning = (userId, establishmentId, params) => dispatch => {
  dispatch(handleLoading(true));

  return debitWarning(userId, establishmentId, params)
    .then(({ data }) => {
      toast.success(data);
      dispatch(loadBusinessInfo({ userId, establishmentId }));
    })
    .finally(() => dispatch(handleLoading(false)));
}