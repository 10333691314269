import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Card from 'components/Card';
import Modal from 'components/Modal';
import Button from 'components/Button';
import FileUploader from 'components/FileUploader';
import Loader from 'components/Loader';
import Input from 'components/Input';
import Select from 'components/Select';

import {
  loadProvisionalReceiptInfo,
  loadEconomicActivities,
  loadEstablishmentsWithConfig,
  attachEstablishment,
  detachEstablishment
} from '../../../../store/thunk';

import { loadAddress } from '../../../../../BusinessInfo/store/thunk';
import { clearAddress } from '../../../../../BusinessInfo/store/actions';
import { setLogoFile, setConfigType, setSelectedEstablishmentsWithConfig } from '../../../../store/actions';

import { formatDocument, formatPostalCode } from 'helpers/formatters';

export default function BusinessInfo({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);
  const { isLoading, isPostalCodeLoading } = useSelector(state => state.businessInfo);

  const {
    logoFile,
    configType,
    establishmentsWithConfig,
    selectedEstablishmentsWithConfig,
    submitErrors,
    submit: {
      taxSettingsOwner,
      establishmentTaxSettingOwner
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const formattedEstablishmentsWithConfig = establishmentsWithConfig?.map(establishment => {
    return {
      label: `${establishment?.companyName} (${formatDocument(establishment?.documentId)})`,
      value: establishment?.taxSettingsUuid
    }
  });

  const configTypeOptions = [
    {
      type: 'newCnpj',
      label: 'Novo CNPJ para esta unidade',
      value: '1'
    },
    {
      type: 'sameCnpj',
      label: 'Mesmo CNPJ de outro estabelecimento',
      value: '0'
    }
  ];

  const [isNewCnpjModalOpen, setIsNewCnpjModalOpen] = useState(false);
  const [isSameCnpjModalOpen, setIsSameCnpjModalOpen] = useState(false);
  const [selectedEstablishmentsWithConfigUuid, setSelectedEstablishmentsWithConfigUuid] = useState(null);

  const selectedEstablishmentsWithConfigFromAPI = establishmentsWithConfig
    ?.find(establishment => establishment?.documentId == formikProps.values.documentId)
    ?.taxSettingsUuid || null;

  const selectedEstablishmentNameWithConfigFromAPI = establishmentsWithConfig
    ?.find(establishment => establishment?.taxSettingsUuid == selectedEstablishmentsWithConfigUuid)
    ?.companyName || null;

  const isSameCnpj = +configType == 0;

  useEffect(() => {
    dispatch(setConfigType(taxSettingsOwner));
  }, [taxSettingsOwner]);

  useEffect(() => {
    dispatch(loadEconomicActivities(userId));
  }, []);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
      dispatch(loadEstablishmentsWithConfig(userId, establishmentId));
    }
  }, [establishmentId]);

  const handlePostalCode = e => {
    const { name, value } = e.target;

    formikProps.setFieldValue(name, value);

    if(value.length === 9) {
      return dispatch(loadAddress(value)).then(data => {
        const { logradouro, bairro, localidade, uf } = data;
        formikProps.setFieldValue('street', logradouro);
        formikProps.setFieldValue('district', bairro);
        formikProps.setFieldValue('city', localidade);
        formikProps.setFieldValue('state', uf);
      });
    }

    return dispatch(clearAddress());
  }

  const handleUploadFile = (e, formikNewProps) => {
    const logo = e.target.files[0];
    const { setFieldValue } = formikNewProps;

    setFieldValue('logo', logo);

    return dispatch(setLogoFile(logo));
  }

  return(
    <Card
      title="Preencha as informações básicas do negócio"
      key={formikProps?.values?.establishmentId}
    >
      {isSameCnpjModalOpen && (
        <Modal
          title="Vincular configuração"
          open={isSameCnpjModalOpen}
          onClose={() => setIsSameCnpjModalOpen(false)}
          modalActions={
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item>
                <Button
                  color="error"
                  onClick={() => setIsSameCnpjModalOpen(false)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    dispatch(setSelectedEstablishmentsWithConfig(selectedEstablishmentsWithConfigUuid));
                    dispatch(detachEstablishment(userId, establishmentId));
                    dispatch(attachEstablishment(userId, establishmentId, selectedEstablishmentsWithConfigUuid));
                    setIsSameCnpjModalOpen(false);
                  }}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Typography>
            Ao selecionar esta opção a configuração da empresa <b>{selectedEstablishmentNameWithConfigFromAPI}</b> será vinculada na configuração de nota fiscal do seu estabelecimento.
          </Typography>
        </Modal>
      )}
      {isNewCnpjModalOpen && (
        <Modal
          title="Desvincular configuração"
          open={isNewCnpjModalOpen}
          onClose={() => setIsNewCnpjModalOpen(false)}
          modalActions={
            <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Grid item>
                <Button
                  color="error"
                  onClick={() => setIsNewCnpjModalOpen(false)}
                >
                  Cancelar
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  onClick={() => {
                    dispatch(setConfigType(1));
                    dispatch(detachEstablishment(userId, establishmentId));
                    dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
                    setIsNewCnpjModalOpen(false);
                  }}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Typography>
            Ao selecionar esta opção o estabelecimento ficará sem configuração de nota fiscal até que uma nova seja cadastrada.
          </Typography>
        </Modal>
      )}
      {(+taxSettingsOwner == 0) && (
        <Grid item xs={12}>
          <Grid
            container
            style={{
              flexDirection: 'column',
              marginTop: 15,
              backgroundColor: '#f6ebe9',
              border: '1px solid #efd7d7',
              borderRadius: 10
            }}
          >
            <Grid
              item
              spacing={2}
              style={{ padding: 10 }}
            >
              <Typography style={{ color: '#aa513d', margin: 5 }}>
                Se quiser editar a configuração precisa acessar essa tela com o estabelecimento
                <b>{` ${establishmentTaxSettingOwner?.name} ${establishmentTaxSettingOwner?.address?.street}, ${establishmentTaxSettingOwner?.address?.number} - ${establishmentTaxSettingOwner?.address?.district}, ${establishmentTaxSettingOwner?.address?.city}`}.</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Form>
          <Loader isLoading={isLoading}>
            {(establishmentsWithConfig?.length > 0) && (
              <Grid container style={{ flexDirection: 'column' }}>
                <Grid item spacing={2}>
                  <Typography variant="h6" color="primary">
                    Escolha a opção que mais se enquadra nesta configuração de emissão de
                    nota do novo estabelecimento antes de prosseguir o cadastro
                  </Typography>
                </Grid>
                <Grid item spacing={2} style={{ marginTop: 15 }}>
                  <Select
                    label="Selecione a opção"
                    value={configType?.toString()}
                    options={configTypeOptions}
                    onChange={event => {
                      dispatch(setSelectedEstablishmentsWithConfig(''));

                      if(+event.target.value == 0) {
                        dispatch(setConfigType(0));
                      }

                      if(+event.target.value == 1) {
                        setIsNewCnpjModalOpen(true);
                      }
                    }}
                  />
                </Grid>
                {(configType == 0) && (
                  <Grid item spacing={2} style={{ marginTop: 15 }}>
                    <Select
                      label="Qual CNPJ?"
                      value={selectedEstablishmentsWithConfigFromAPI || selectedEstablishmentsWithConfig || ""}
                      options={formattedEstablishmentsWithConfig}
                      onChange={event => {
                        setSelectedEstablishmentsWithConfigUuid(event.target.value);
                        setIsSameCnpjModalOpen(true);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid container style={{ flexDirection: 'column', marginTop: 30 }}>
              <Grid item spacing={2}>
                <Typography variant="h6" color="primary">
                  Escolha a logomarca que vai no título da nota fiscal
                </Typography>
              </Grid>
              <Grid item spacing={2}>
                <Typography
                  variant="subtitle2"
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    fontStyle: 'italic',
                    fontsSize: 12,
                    fontWeight: 400,
                    color: 'grey',
                  }}
                >
                  Tamanho recomendado 160x130. Apenas nos formatos PNG, JPEG, e
                  JPG. (Opcional)
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <FileUploader
                  name="logo"
                  file={logoFile}
                  errors={formikProps.errors.logo}
                  onChange={e => handleUploadFile(e, formikProps)}
                >
                  Escolha um arquivo
                </FileUploader>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h6" color="primary">
                  Informações básicas do negócio
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  autoFocus
                  name="fantasyName"
                  label="Nome do estabelecimento"
                  value={formikProps.values.fantasyName || ''}
                  error={
                    formikProps.errors.fantasyName ||
                    submitErrors?.some(error => error?.includes('fantasyName'))
                  }
                  helperText={
                    formikProps.errors.fantasyName ||
                    submitErrors?.find(error => error?.includes('fantasyName'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="documentId"
                  type="document"
                  label="CNPJ"
                  placeholder="00.000.000/0000-00"
                  value={formatDocument(formikProps.values.documentId) || ''}
                  error={
                    formikProps.errors.documentId ||
                    submitErrors?.some(error => error?.includes('documentId'))
                  }
                  helperText={
                    formikProps.errors.documentId ||
                    submitErrors?.find(error => error?.includes('documentId'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="phone"
                  type="phone"
                  label="Telefone"
                  placeholder="(00) 00000-0000"
                  value={formikProps.values.phone || ''}
                  error={
                    formikProps.errors.phone ||
                    submitErrors?.some(error => error?.includes('phone'))
                  }
                  helperText={
                    formikProps.errors.phone ||
                    submitErrors?.find(error => error?.includes('phone'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="email"
                  type="email"
                  label="Email"
                  placeholder="usuario@exemplo.com.br"
                  value={formikProps.values.email || ''}
                  error={
                    formikProps.errors.email ||
                    submitErrors?.some(error => error?.includes('email'))
                  }
                  helperText={
                    formikProps.errors.email ||
                    submitErrors?.find(error => error?.includes('email'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Input
                disabled={isSameCnpj}
                name="companyName"
                label="Razão social"
                value={formikProps.values.companyName || ''}
                error={
                  formikProps.errors.companyName ||
                  submitErrors?.some(error => error?.includes('companyName'))
                }
                helperText={
                  formikProps.errors.companyName ||
                  submitErrors?.find(error => error?.includes('companyName'))
                }
                onChange={formikProps.handleChange}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="h6" color="primary">
                  Local onde o serviço é prestado
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item lg={2} sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="postalCode"
                  type="postalCode"
                  label="CEP"
                  placeholder="00000-000"
                  value={formatPostalCode(formikProps.values.postalCode) || ''}
                  error={
                    formikProps.errors.postalCode ||
                    submitErrors?.some(error => error?.includes('postalCode'))
                  }
                  helperText={submitErrors?.find(error => error?.includes('postalCode'))}
                  onChange={e => handlePostalCode(e)}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="street"
                  type="text"
                  label="Endereço"
                  placeholder="Endereço"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.street || ''}
                  error={
                    formikProps.errors.street ||
                    submitErrors?.some(error => error?.includes('street'))
                  }
                  helperText={
                    formikProps.errors.street ||
                    submitErrors?.find(error => error?.includes('street'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item lg={2} sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="number"
                  type="number"
                  label="Número"
                  placeholder="0"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.number || ''}
                  error={formikProps.errors.number}
                  helperText={formikProps.errors.number}
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item lg={2} sm={6} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="complement"
                  type="text"
                  label="Complemento"
                  placeholder="Ex.: 3˚ andar"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.complement || ''}
                  error={formikProps.errors.complement}
                  helperText={formikProps.errors.complement}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item sm={5} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="district"
                  type="text"
                  label="Bairro"
                  placeholder="Bairro"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.district || ''}
                  error={
                    formikProps.errors.district ||
                    submitErrors?.some(error => error?.includes('district'))
                  }
                  helperText={
                    formikProps.errors.district ||
                    submitErrors?.find(error => error?.includes('district'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={5} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="city"
                  type="text"
                  label="Cidade"
                  placeholder="Cidade"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.city || ''}
                  error={
                    formikProps.errors.city ||
                    submitErrors
                      ?.find(error => error?.includes('city'))
                      ?.split(' ')
                      ?.some(error => error == 'city')
                  }
                  helperText={
                    formikProps.errors.city ||
                    submitErrors
                      ?.find(error => error?.includes('city'))
                      ?.split(' ')
                      ?.some(error => error == 'city') &&
                    submitErrors?.find(error => error?.includes('city'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
              <Grid item sm={2} xs={12}>
                <Input
                  disabled={isSameCnpj}
                  name="state"
                  type="text"
                  label="UF"
                  placeholder="UF"
                  loading={isPostalCodeLoading}
                  value={formikProps.values.state || ''}
                  error={
                    formikProps.errors.state ||
                    submitErrors
                      ?.find(error => error?.includes('state'))
                      ?.split(' ')
                      ?.some(error => error == 'state')
                  }
                  helperText={
                    formikProps.errors.state ||
                    submitErrors
                      ?.find(error => error?.includes('state'))
                      ?.split(' ')
                      ?.some(error => error == 'state') &&
                    submitErrors?.find(error => error?.includes('state'))
                  }
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
          </Loader>
        </Form>
      </Grid>
    </Card>
  );
}

BusinessInfo.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      fantasyName: '',
      establishmentName: '',
      companyName: '',
      documentId: '',
      phone: '',
      email: '',
      postalCode: '',
      street: '',
      number: 0,
      complement: '',
      district: '',
      city: '',
      state: '',
      vacanciesMarks: ''
    },
    errors: {
      fantasyName: false,
      companyName: false,
      documentId: false,
      phone: false,
      email: false,
      postalCode: false,
      street: false,
      number: false,
      complement: false,
      district: false,
      city: false,
      state: false,
      vacanciesMarks: false
    }
  }
};

BusinessInfo.propTypes = {
  formikProps: PropTypes.shape()
};