import { makeStyles } from '@material-ui/core/styles';

const backgroundColor = process.env.REACT_APP_RESALE_LOGIN_BACKGROUND_COLOR;
const paperBackgroundColor = process.env.REACT_APP_RESALE_LOGIN_PAPER_COLOR;

export const useStyles = makeStyles(({ palette, spacing }) => ({
  container: {
    minHeight: '100vh',
    padding: spacing(1),
    backgroundColor: backgroundColor || palette.secondary.main
  },
  paper: {
    textAlign: 'center',
    padding: spacing(4),
    backgroundColor: paperBackgroundColor || '#FFFFFF'
  },
  title: {
    fontWeight: 600
  },
  divider: {
    marginTop: spacing(2),
    marginBottom: spacing(2)
  },
  linkContainer: {
    marginTop: spacing(1),
    marginBottom: spacing(2)
  },
  link: {
    textDecoration: 'none'
  },
  version: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 500,
    marginTop: spacing(2)
  }
}));