import React from 'react';
import PropTypes from 'prop-types';
import MaterialTable from 'material-table';

import { tableIcons } from './tableIcons';

import './MTable.scss';

const width = window.innerWidth;
const tableRowWidth = width < 768 ? 120 : 100;

const MTable = props => {
  const {
    hasSorting,
    headers,
    data,
    actions,
    multiselect,
    loading,
    selectRow,
    rowStyle,
    actionColumnWidth,
    onSelectionChange,
    textActions,
    lineBreak = false,
    showSelectAllCheckbox = true
  } = props;

  const actionColumnRealWidth = actionColumnWidth || tableRowWidth;

  const width = window.innerWidth;

  const isMobile = width < 1024;
  const iconLeft = isMobile ? 0 : -1;

  const headerBackgroundColor = process.env.REACT_APP_RESALE_HEADER_BACKGROUND_COLOR || '#1E5168';

  return(
    <>
      <MaterialTable
        options={{
          showSelectAllCheckbox,
          toolbar: false,
          paging: false,
          draggable: false,
          sorting: hasSorting || false,
          selection: multiselect || false,
          headerStyle: {
            backgroundColor: headerBackgroundColor,
            color: '#FFFFFF',
            textAlign: 'center',
            width: tableRowWidth,
            minWidth: tableRowWidth
          },
          rowStyle,
          cellStyle: {
            textAlign: 'center',
            width: tableRowWidth,
            minWidth: tableRowWidth,
            whiteSpace: lineBreak ? 'break-spaces' : 'none'
          },
          actionsCellStyle: {
            width: actionColumnRealWidth,
            minWidth: actionColumnRealWidth,
            textAlign: 'center'
          },
          actionsColumnIndex: iconLeft
        }}
        style={{ boxShadow: 'none' }}
        localization={{
          header: {
            actions: textActions
          },
          body: {
            emptyDataSourceMessage: 'Não foram encontrados itens nessa tabela'
          }
        }}
        columns={headers}
        data={data}
        isLoading={loading}
        actions={multiselect ? false : actions}
        icons={tableIcons}
        emptyValue="Não foram encontrados itens nessa tabela"
        onRowClick={
          selectRow ?
          (event, rowData) => {
            selectRow(rowData);
          }
          : false
        }
        onSelectionChange={rows => onSelectionChange(rows)}
      />
    </>
  );
}

MTable.propTypes = {
  hasSorting: PropTypes.bool,
  headers: PropTypes.shape(PropTypes.arrayOf()).isRequired,
  data: PropTypes.shape(PropTypes.arrayOf()).isRequired,
  actions: PropTypes.shape(PropTypes.arrayOf()).isRequired,
  multiselect: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  selectRow: PropTypes.func,
  rowStyle: PropTypes.string,
  textActions: PropTypes.string,
  actionColumnWidth: PropTypes.number.isRequired,
  onSelectionChange: PropTypes.func.isRequired
};

MTable.defaultProps = {
  textActions: 'Ações',
  hasSorting: false,
  multiselect: false,
  selectRow: () => {},
  rowStyle: {}
};

export default MTable;