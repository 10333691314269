import React from 'react';
import moment from 'moment';
import Container from '@material-ui/core/Container';

import { useStyles } from './styles';

const Footer = () => {
  const classes = useStyles();

  const isResale = !!process.env.REACT_APP_RESALE_ID;
  const resaleTermsOfUseUrl = process.env.REACT_APP_RESALE_TERMS_OF_USE;
  const resalePrivacyPolicyUrl = process.env.REACT_APP_RESALE_PRIVACY_POLICY;

  return(
    <Container className={classes.footer}>
      {!isResale && (
        <>
          <span className={classes.footerText}>
            Todos os direitos reservados a Jump Tecnologia | {moment().year()}
          </span>
          &nbsp; &copy; &nbsp; &middot; &nbsp;
        </>
      )}
      <a
        href={
          isResale
            ? resaleTermsOfUseUrl
            : "https://jumppark.com.br/wp-content/uploads/2021/06/Termo-de-Uso.pdf"
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#757575' }}
      >
        Termo de uso
      </a>
      &nbsp; &middot; &nbsp;
      <a
        href={
          isResale
            ? resalePrivacyPolicyUrl
            : "https://jumppark.com.br/wp-content/uploads/2021/06/Politica-de-Privacidade-2.pdf"
        }
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: '#757575' }}
      >
        Política de privacidade
      </a>
    </Container>
  );
}

export default Footer;