import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { set, lensProp } from 'ramda';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { Tooltip } from '@material-ui/core';

import Status from '../../../../../components/Status';
import Card from '../../../../../components/Card';
import Loader from '../../../../../components/Loader';
import Select from '../../../../../components/Select';

import { loadPaymentMethods } from 'pages/PaymentMethods/store/thunk';
import { loadProvisionalReceiptInfo } from '../../../store/thunk';

import './ProvisionalReceiptFilter.scss';

export default function ProvisionalReceiptFilter({ formikProps }) {
  const dispatch = useDispatch();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId } = useSelector(state => state.businessInfo);

  const {
    isLoading,
    configType,
    submit: {
      taxSettingsOwner,
      establishmentTaxSettingOwner
    }
  } = useSelector(state => state.provisionalReceiptConfig);

  const isSameCnpj = +configType == 0;

  const { automaticGenerate } = formikProps.values;
  const setAutomaticGenerate = (name, value) => set(lensProp(name), value, automaticGenerate);

  const selectedPaymentMethods =
    useSelector(state => state.paymentMethods.list)
    .map(paymentMethod => {
      if(automaticGenerate?.paymentMethods?.includes(paymentMethod.establishmentPaymentMethodId)) {
        return paymentMethod.establishmentPaymentMethodId;
      }
    })
    .filter(paymentMethod => Number(paymentMethod));

  const availablePaymentMethods =
    useSelector(state => state.paymentMethods.list)
    .map(paymentMethod => {
      return {
        label: paymentMethod.paymentMethodName,
        value: paymentMethod.establishmentPaymentMethodId
      }
    });

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadPaymentMethods({ userId, establishmentId, page: null, pageSize: null }));
    }
  }, []);

  useEffect(() => {
    if(establishmentId) {
      dispatch(loadProvisionalReceiptInfo(userId, establishmentId));
    }
  }, [establishmentId]);

  const handleAutomaticGenerateCheckbox = e => {
    const { name, checked } = e.target;
    formikProps.setFieldValue('automaticGenerate', setAutomaticGenerate(name, +checked));
  }

  const handleAutomaticGenerateSelect = e => {
    const { name, value } = e.target;
    formikProps.setFieldValue('automaticGenerate', setAutomaticGenerate(name, value));
  }

  return (
    <Card title="Configuração de filtro de envio automático de NFS-e">
      {(+taxSettingsOwner == 0) && (
        <Grid item xs={12}>
          <Grid
            container
            style={{
              flexDirection: 'column',
              marginTop: 15,
              marginBottom: 15,
              backgroundColor: '#f6ebe9',
              border: '1px solid #efd7d7',
              borderRadius: 10
            }}
          >
            <Grid
              item
              spacing={2}
              style={{ padding: 10 }}
            >
              <Typography style={{ color: '#aa513d', margin: 5 }}>
                Se quiser editar a configuração precisa acessar essa tela com o estabelecimento
                <b>{` ${establishmentTaxSettingOwner?.name} ${establishmentTaxSettingOwner?.address?.street}, ${establishmentTaxSettingOwner?.address?.number} - ${establishmentTaxSettingOwner?.address?.district}, ${establishmentTaxSettingOwner?.address?.city}`}.</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Loader isLoading={isLoading}>
          <Grid
            container
            style={{
              flexDirection: 'column',
              padding: 15,
              backgroundColor: 'rgb(241, 244, 249)',
              border: '1px solid #38a8d8',
              borderRadius: 10
            }}
          >
            <Grid item spacing={2}>
              <Typography variant="h6" color="primary">Atenção:</Typography>
              <Typography
                variant="h6"
                style={{ fontWeight: 400, fontSize: '15px' }}
              >
                1. Após a edição ou cadastro, sincronize o aplicativo operacional
                para começar a gerar as RPS e, quando sincronizada as notas
                fiscais.
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Tooltip
                title={<Typography>Emitir de todas as Ordens de serviços pagas</Typography>}
                placement="top-start"
                enterTouchDelay={0}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="serviceOrders"
                      checked={automaticGenerate.serviceOrders}
                      onChange={handleAutomaticGenerateCheckbox}
                    />
                  }
                  label="Todas as ordens de serviços"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title={<Typography>Emitir de todas as Faturas pagas</Typography>}
                placement="top-start"
                enterTouchDelay={0}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="invoices"
                      checked={automaticGenerate.invoices}
                      onChange={handleAutomaticGenerateCheckbox}
                    />
                  }
                  label="Recebimento apenas de faturas"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title={<Typography>Emitir quando o cliente solicitar e incluir os dados dele</Typography>}
                placement="top-start"
                enterTouchDelay={0}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="selectedTransactions"
                      checked={automaticGenerate.selectedTransactions}
                      onChange={handleAutomaticGenerateCheckbox}
                    />
                  }
                  label="Recebimento quando solicitada a nota fiscal"
                />
              </Tooltip>
            </Grid>
            <Tooltip
              title={<Typography>Emitir apenas quando tiver um desses meios de pagamento, tanto fatura quanto Ordem de Serviço</Typography>}
              placement="top-start"
              enterTouchDelay={0}
            >
              <Grid item xs={12}>
                <Select
                  multiple
                  name="paymentMethods"
                  label="Recebimento apenas dos respectivos meios de pagamento"
                  options={availablePaymentMethods}
                  value={selectedPaymentMethods || []}
                  onChange={handleAutomaticGenerateSelect}
                />
              </Grid>
            </Tooltip>
            <Grid container spacing={2}>
              <Grid item sm={12} xs={12}>
                <Status
                  name="statusRps"
                  value={formikProps.values.statusRps}
                  onChange={formikProps.handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Select
                disabled={isSameCnpj}
                name="automaticSendNfse"
                label="Envio automático de notas fiscais"
                options={[
                  { label: 'Desativado', value: 0 },
                  { label: 'Ativado', value: 1 }
                ]}
                value={formikProps.values.automaticSendNfse}
                onChange={formikProps.handleChange}
              />
            </Grid>
          </Grid>
        </Loader>
      </Grid>
    </Card>
  );
}

ProvisionalReceiptFilter.propTypes = {
  formikProps: PropTypes.shape().isRequired
}