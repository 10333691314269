import { styled, makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Link';

const headerColor = process.env.REACT_APP_RESALE_HEADER_BACKGROUND_COLOR;

export const MenuHorizontal = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
});

export const ButtonItens = styled(Button)({
  display: 'flex',
  flexDirection: 'row'
});

export const useStyles = makeStyles(theme => ({
  root: {
    border: 'none',
    backgroundColor: headerColor || theme.palette.primary.main
  },
  container: {
    maxWidth: '1280px !important'
  },
  logo: {
    flexGrow: 1,
    color: theme.palette.common.white,
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px'
  },
  menuRow: {
    flexDirection: 'row-reverse',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  menuMobileItem: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      color: 'inherit'
    }
  },
  menuListSectionsContent: {},
  subMenuTitle: {
    paddingTop: theme.spacing(0.75),
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(0.75),
    backgroundColor: 'rgba(228, 231, 234)',
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    fontSize: '1rem',
    fontWeight: '500',
    minWidth: '180px'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  menuIcon: {
    marginRight: theme.spacing(1)
  },
  versionItem: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderTop: '1px solid #CCC'
  }
}));