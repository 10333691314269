import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import { omit } from 'ramda';

import { Formik, Form } from 'formik';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';

import Card from '../../../components/Card';
import CardFooter from '../../../components/CardFooter';
import Input from '../../../components/Input';
import Loader from '../../../components/Loader';
import Button from '../../../components/Button';

import { loadProfile, upsertUser } from '../store/thunk';
import { clearProfile, clearPassword } from '../store/actions';

import { validations } from './validations';

export default function Profile({ formikProps }) {
  const dispatch = useDispatch();

  const { path } = useRouteMatch();
  const isInitialRegistration = path.split('/').includes('cadastro-inicial');

  const isResale = !!process.env.REACT_APP_RESALE_ID;

  const { userId, submit, isLoading } = useSelector(state => state.profile);

  const initialValues = { userId, ...submit }

  useEffect(() => {
    if(userId) {
      dispatch(loadProfile(userId));
    } else {
      dispatch(clearProfile());
    }
  }, [userId]);

  const handleCheckboxChange = (e, setFieldValue) => {
    const { name, checked } = e.target;
    setFieldValue(name, checked);
    formikProps.setFieldValue(name, checked);
  }

  const handleProfileSave = (values, actions) => {
    const { resetForm } = actions;
    const params = omit(['termsOfUse', 'emailConfirm', 'isInitialRegistration', 'isProfile'], values);

    dispatch(upsertUser(params)).then(() => {
      dispatch(clearPassword());
      resetForm();
    });
  }

  return(
    <Card title="Preencha suas informações">
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          isResale,
          isProfile: true,
        }}
        validationSchema={validations}
        onSubmit={handleProfileSave}
      >
        {({ ...formikNewProps }) => {
          const {
            errors,
            handleChange,
            setFieldValue,
            values: {
              name,
              email,
              emailConfirm,
              phone,
              password,
              passwordConfirm,
              termsOfUse
            }
          } = formikNewProps;

          return(
            <Grid item xs={12}>
              <Form>
                <Loader isLoading={isLoading}>
                  <Grid item xs={12}>
                    <Input
                      autoFocus
                      name="name"
                      label="Seu nome"
                      value={name || formikProps.values.name}
                      error={errors.name || formikProps.errors.name}
                      helperText={errors.name || formikProps.errors.name}
                      onChange={e => {
                        handleChange(e);
                        formikProps.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      noAutoComplete={isInitialRegistration}
                      name="phone"
                      type="phone"
                      label="Telefone"
                      placeholder="(00) 0000-0000"
                      value={phone || formikProps.values.phone}
                      error={errors.phone || formikProps.errors.phone}
                      helperText={errors.phone || formikProps.errors.phone}
                      onChange={e => {
                        handleChange(e);
                        formikProps.handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={isInitialRegistration ? 6 : 12} xs={12}>
                      <Input
                        noAutoComplete={isInitialRegistration}
                        required
                        name="email"
                        label="Email"
                        placeholder="usuario@exemplo.com"
                        value={email || formikProps.values.email}
                        error={errors.email || formikProps.errors.email}
                        helperText={errors.email || formikProps.errors.email}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    {isInitialRegistration && (
                      <Grid item md={6} xs={12}>
                        <Input
                          noAutoComplete
                          required
                          name="emailConfirm"
                          label="Confirmar email"
                          placeholder="usuario@exemplo.com"
                          value={emailConfirm || formikProps.values.emailConfirm}
                          error={errors.emailConfirm || formikProps.errors.emailConfirm}
                          helperText={errors.emailConfirm || formikProps.errors.emailConfirm}
                          onChange={e => {
                            handleChange(e);
                            formikProps.handleChange(e);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                      <Input
                        name="password"
                        type="password"
                        label="Senha"
                        placeholder="Senha"
                        value={password || formikProps.values.password}
                        error={errors.password || formikProps.errors.password}
                        helperText={errors.password || formikProps.errors.password}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Input
                        name="passwordConfirm"
                        type="password"
                        label="Confirmar senha"
                        placeholder="Confirmar senha"
                        value={passwordConfirm || formikProps.values.passwordConfirm}
                        error={errors.passwordConfirm || formikProps.errors.passwordConfirm}
                        helperText={errors.passwordConfirm || formikProps.errors.passwordConfirm}
                        onChange={e => {
                          handleChange(e);
                          formikProps.handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {isInitialRegistration && (
                    <Grid container xs={12} alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="subtitle2" color="primary">
                          <a
                            href="https://jumppark.com.br/wp-content/uploads/2021/06/Termo-de-Uso.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Acesse os termos de uso
                          </a>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="termsOfUse"
                              type="checkbox"
                              checked={termsOfUse || formikProps.values.termsOfUse}
                              onChange={e => handleCheckboxChange(e, setFieldValue)}
                              value={termsOfUse || formikProps.values.termsOfUse}
                            />
                          }
                          label={
                            <Typography style={{ fontSize: 12 }}>
                              Eu li e aceito todas as informações descritas nos
                              termos de uso
                            </Typography>
                          }
                        />
                      </Grid>
                      {formikProps.errors.termsOfUse && (
                        <FormHelperText error>
                          {formikProps.errors.termsOfUse}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                  {(isInitialRegistration && !isResale) && (
                    <Grid container xs={12} alignItems="center" style={{ marginTop: 15 }}>
                      <Grid item xs={12}>
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
                          hl="pt-BR"
                          type="image"
                          onChange={token => formikProps.setFieldValue('recaptcha', !!token)}
                        />
                      </Grid>
                      {(formikProps.errors.recaptcha) && (
                        <FormHelperText error>
                          {formikProps.errors.recaptcha}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                </Loader>
                {!isInitialRegistration && (
                  <CardFooter>
                    <Button
                      type="submit"
                      color="primary"
                      loading={isLoading}
                    >
                      Salvar
                    </Button>
                  </CardFooter>
                )}
              </Form>
            </Grid>
          );
        }}
      </Formik>
    </Card>
  );
}

Profile.defaultProps = {
  formikProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    errors: {
      name: false,
      email: false,
      phone: false,
      password: false,
      passwordConfirm: false,
      termsOfUse: false
    },
    values: {
      name: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      termsOfUse: false
    }
  }
};

Profile.propTypes = {
  formikProps: PropTypes.shape()
};